var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(_vm.displayName)+" "),_c('v-data-table',{attrs:{"items":_vm.tableData,"disable-pagination":"","hide-default-footer":"","sort-by":"Date","headers":[
      { text: 'Date', value: 'date' },
      { text: 'Time In', value: 'in' },
      { text: 'Time Out', value: 'out' },
      { text: 'Hours', value: 'hours' },
      { text: 'Edit', value: 'edit' } ]},scopedSlots:_vm._u([{key:"item.hours",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.hours.toFixed(2))+" ")]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th'),_c('th'),_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm.totalHours.toFixed(2)))])])])],2),(_vm.dialogItem)?_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edit Stuff ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-date-picker',{attrs:{"no-title":""},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Start Time"},model:{value:(_vm.editedItem.startTime),callback:function ($$v) {_vm.$set(_vm.editedItem, "startTime", $$v)},expression:"editedItem.startTime"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"End Time"},model:{value:(_vm.editedItem.endTime),callback:function ($$v) {_vm.$set(_vm.editedItem, "endTime", $$v)},expression:"editedItem.endTime"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"width":"50%"},on:{"click":function($event){_vm.showEditDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"width":"50%"},on:{"click":_vm.submitEdit}},[_vm._v("Submit")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }