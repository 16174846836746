<template>
  <div>
    {{ displayName }}
    <v-data-table
      :items="tableData"
      disable-pagination
      hide-default-footer
      sort-by="Date"
      :headers="[
        { text: 'Date', value: 'date' },
        { text: 'Time In', value: 'in' },
        { text: 'Time Out', value: 'out' },
        { text: 'Hours', value: 'hours' },
        { text: 'Edit', value: 'edit' },
      ]"
    >
      <template v-slot:item.hours="{ item }">
        {{ item.hours.toFixed(2) }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn icon @click="editItem(item)">
          <v-icon small>
            edit
          </v-icon>
        </v-btn>
      </template>
      <template slot="body.append">
        <tr>
          <th></th>
          <th></th>
          <th>Total</th>
          <th>{{ totalHours.toFixed(2) }}</th>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-if="dialogItem" max-width="800" v-model="showEditDialog">
      <v-card>
        <v-card-title>
          Edit Stuff
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-date-picker no-title v-model="editedItem.date" />
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Start Time"
                    v-model="editedItem.startTime"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="End Time" v-model="editedItem.endTime" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn width="50%" @click="showEditDialog = false">Cancel</v-btn>
          <v-btn width="50%" @click="submitEdit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'UserReport',
  props: { timeCards: Array },
  data: () => ({
    showEditDialog: false,
    dialogItem: null,
    editedItem: {
      date: null,
      startTime: null,
      endTime: null,
    },
  }),
  computed: {
    displayName() {
      return (
        this.timeCards[0].user.firstName + ' ' + this.timeCards[0].user.lastName
      )
    },
    tableData() {
      return this.timeCards.map((card) => {
        return {
          date: this.getCardDate(card),
          in: this.prettyTime(card.startTime),
          out: this.prettyTime(card.endTime),
          hours: this.cardHours(card),
          id: card.id,
        }
      })
    },
    totalHours() {
      return this.timeCards
        .map((card) => this.cardHours(card))
        .reduce((prev, curr) => prev + curr)
    },
  },
  methods: {
    getCardDate(card) {
      const startDate = new Date(card.startTime).toLocaleDateString()
      const endDate = new Date(card.endTime).toLocaleDateString()
      if (startDate != endDate) {
        return 'Error: this time card spans more than one day'
      }
      return startDate
    },
    prettyTime(timeString) {
      return new Date(timeString).toLocaleTimeString()
    },
    cardHours(card) {
      const startTime = new Date(card.startTime).getTime()
      const endTime = new Date(card.endTime).getTime()
      const durationMs = endTime - startTime
      return durationMs / 1000 / 60 / 60
    },
    editItem(item) {
      this.dialogItem = this.timeCards.find((tc) => tc.id == item.id)

      this.editedItem = {
        date: this.dialogItem.startTime.substr(0, 10),
        startTime: this.prettyTime(this.dialogItem.startTime),
        endTime: this.prettyTime(this.dialogItem.endTime),
      }
      this.showEditDialog = !this.showEditDialog
    },
    submitEdit() {
      let startTime = new Date(this.editedItem.date)
      startTime.setTime(
        startTime.getTime() + startTime.getTimezoneOffset() * 60 * 1000
      )
      let endTime = new Date(startTime)
      let [startHours, startMinutes] = this.convertTime(
        this.editedItem.startTime
      ).split(':')
      startTime.setHours(startHours)
      startTime.setMinutes(startMinutes)
      let [endHours, endMinutes] = this.convertTime(
        this.editedItem.endTime
      ).split(':')
      endTime.setHours(endHours)
      endTime.setMinutes(endMinutes)
      const payload = {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      }
      this.axios
        .put(`time-cards/admin/${this.dialogItem.id}`, payload)
        .then((res) => {
          console.log(res.data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    convertTime(timeStr) {
      const [time, modifier] = timeStr.split(' ')
      let [hours, minutes] = time.split(':')
      if (hours === '12') {
        hours = '00'
      }
      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12
      }
      return `${hours}:${minutes}`
    },
  },
}
</script>

<style></style>
