<template>
  <v-card outlined :color="cardColor" @click="toggleClock">
    <v-card-title> {{ user.firstName }} {{ user.lastName }} </v-card-title>
    <v-card-text>
      {{ status }} <span v-if="clockedIn">at {{ clockHour }}</span>
      <v-dialog width="400" v-model="showPinDialog">
        <pin v-on:submit-pin="submitPin($event)" />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import Pin from './Pin.vue'
export default {
  components: { Pin },
  name: 'UserCard',
  props: { user: Object },
  data: () => ({
    timeCard: null,
    showPinDialog: false,
  }),
  methods: {
    async getTimeCard() {
      this.querying = true
      try {
        const timeCard = await this.axios.get(`users/${this.user.id}/timecard`)
        this.timeCard = timeCard.data
        return (this.querying = false)
      } catch (error) {
        console.log(error)
        return (this.querying = false)
      }
    },
    toggleClock() {
      this.showPinDialog = true
    },
    submitPin(pin) {
      this.showPinDialog = false
      if (this.clockedIn) return this.clockOut(pin)
      return this.clockIn(pin)
    },
    clockIn(pin) {
      this.axios
        .post('time-cards', {
          pin: pin,
          employee: this.user,
        })
        .then((res) => {
          this.timeCard = res.data
        })
    },
    clockOut(pin) {
      this.timeCard.endTime = new Date(Date.now())
      this.axios
        .put(`time-cards\\${this.timeCard.id}`, {
          employee: this.user,
          pin: pin,
          timeCard: this.timeCard,
        })
        .then(() => {
          this.timeCard = null
        })
    },
  },
  mounted() {
    this.getTimeCard()
  },
  computed: {
    clockedIn() {
      if (!this.user) return false
      return this.timeCard != null && this.timeCard != ''
    },
    status() {
      if (this.clockedIn) return 'Clocked In'
      return 'Clocked Out'
    },
    cardColor() {
      if (this.clockedIn) return '#E1B724'
      return ''
    },
    clockDate() {
      if (!this.timeCard) return null
      return new Date(this.timeCard.startTime).toLocaleDateString('en-US')
    },
    clockHour() {
      if (!this.timeCard) return null
      return new Date(this.timeCard.startTime).toLocaleTimeString('en-US')
    },
  },
}
</script>
