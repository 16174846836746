<template>
  <v-card>
    <v-card-title>
      Enter Your PIN
    </v-card-title>
    <v-card-subtitle style='height:24px;'>
      {{pin}}
    </v-card-subtitle>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col v-for="j in 9" :key="j" cols="4">
            <v-btn x-large @click="addChar(j)">
              {{ j }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-btn x-large @click="clear()">
              <v-icon>
                clear
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn x-large color="primary" @click="submit()">
              <v-icon>
                done
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn x-large @click="backSpace()">
              <v-icon>
                keyboard_backspace
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'PinField',
  data: () => ({
    pin: '',
  }),
  methods: {
    addChar(char) {
      this.pin += char
    },
    backSpace() {
      this.pin = this.pin.slice(0, this.pin.length - 1)
    },
    clear() {
      this.pin = ''
    },
    submit() {
      this.$emit('submit-pin', this.pin)
      this.pin = ''
    },
  },
}
</script>
