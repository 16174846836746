<template>
  <v-row>
    <v-col>
      <v-row align="center" align-content="center">
        <v-col cols="3">
          <v-img width="125" src="../assets/logo.png" />
        </v-col>
        <v-col>
          <div class="text-h3">
            Time Clock
          </div>
        </v-col>
        <v-col class="text-h3">
          {{ time }}
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
    </v-col>
    <v-row>
      <v-col cols="4" v-for="user in users" :key="user.name">
        <user-card :user="user" />
      </v-col>
      <v-dialog width="400" v-model="showPinDialog">
        <pin v-on:submit-pin="submitPin($event)" />
      </v-dialog>
    </v-row>
  </v-row>
</template>
<script>
import UserCard from '../components/UserCard.vue'
import Pin from '../components/Pin.vue'
export default {
  components: { UserCard, Pin },
  name: 'Clock',
  data: () => ({
    showPinDialog: false,
    time: null,
  }),
  computed: {
    users() {
      return this.$store.state.users
    },
  },
}
</script>
