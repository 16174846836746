<template>
  <v-row>
    <v-col>
      <v-select
        label="Select a pay period"
        v-model="payPeriod"
        :items="payPeriods"
        :item-text="
          (item) =>
            prettyDate(item.startDate) + ' - ' + prettyDate(item.endDate)
        "
        :item-value="(item) => item"
      />
      <div v-for="(employee, index) in employees" :key="index">
        <user-report :timeCards="cardsByEmployee[index]" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserReport from "../components/UserReport.vue";
var qs = require("qs");
export default {
  components: { UserReport },
  name: "Admin",
  data: () => ({
    payPeriods: [],
    payPeriod: null,
    timeCards: [],
  }),
  methods: {
    prettyDate(string) {
      return new Date(string).toLocaleDateString();
    },
    isDateInRange(date, startDate, endDate) {
      return (
        startDate.getTime() < date.getTime() &&
        date.getTime() < endDate.getTime()
      );
    },
    async getTimeSheets() {
      const query = qs.stringify({
        _where: [
          { startTime_gte: this.payPeriod.startDate },
          { endTime_lte: this.payPeriod.endDate },
        ],
      });
      console.log(this.payPeriod);
      let res = await this.axios.get(`time-cards?${query}`);
      this.timeCards = res.data;
    },
  },
  watch: {
    payPeriod() {
      try {
        this.getTimeSheets();
      } catch (error) {
        console.error(error);
      }
    },
  },

  computed: {
    employees() {
      return Array.from(
        new Set(
          this.timeCards.map((card) => {
            return card.user.id;
          })
        )
      );
    },
    cardsByEmployee() {
      return this.employees.map((eid) => {
        return this.timeCards.filter((card) => {
          return card.user.id == eid;
        });
      });
    },
  },
  async mounted() {
    let res;
    try {
      res = await this.axios.get("pay-periods");
      this.payPeriods = res.data;
      let period = this.payPeriods.find((period) => {
        return this.isDateInRange(
          new Date(Date.now()),
          new Date(period.startDate),
          new Date(period.endDate)
        );
      });
      this.payPeriod = period;
      this.getTimeSheets();
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style></style>
